@import '../assets/styles/module/mixins';
@import '../assets/styles/module/variables';
@import '../assets/styles/normalize.scss';






















































.Footer {
  background-color: #eceae6;
  color: $c_black-light;
  display: flex;
  a {
    color: $c_black-light !important;
    font-weight: 700;
    text-decoration: none;
  }
  h3 {
    color: $c_black-light;
    margin-top: 18px;
    margin-bottom: 18px;
    font-size: 1.5rem;
    font-weight: normal;
  }
  &__Left {
    flex: 1;
    border-left: 32px;
    padding-left: 80px;
    ul {
      padding: 0;
      list-style: none;
      line-height: 1.5;
      li {
        margin: 1rem auto;
      }
    }
  }
  &__Right {
    flex: 2;
    padding: 8px;
    text-align: center;
    h3 {
      margin: 1rem auto;
    }
  }
  &__SNS {
    a {
      .fa-facebook,
      .fa-twitter,
      .fa-github {
        color: black;
        transition: 0.4s;
      }
    }
    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      padding: 2rem 0;
      li {
        font-size: 32px;
        padding: 16px;
        transition: 0.4s;
      }
    }
  }
}

@include pc-screen() {
  .Footer {
    padding: 2rem 4rem;
  }
  .Footer__Left {
    flex: 1;
    border-right: 0;
    padding-left: 0;
    margin-left: 80;
  }
}
@include sp-screen() {
  footer {
    flex-direction: column;
    text-align: center;
  }
  .Footer {
    padding: 2rem;
  }
  .Footer__Left {
    flex: 1;
    border-right: 0;
    padding-left: 0;
    margin-left: 0;
  }
  .Footer__Right {
    background-color: #eceae6;
    a {
      color: black;
    }
  }
  .Footer__SNS {
    a {
      .fa-facebook,
      .fa-twitter,
      .fa-github {
        color: black;
        transition: 0.4s;
      }
    }
  }
}
