@import '../assets/styles/module/mixins';
@import '../assets/styles/module/variables';
@import '../assets/styles/normalize.scss';
















































.Header__Nav {
  top: 0;
  background: #fff;
  width: 100%;
  position: fixed;
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 0;
  box-sizing: border-box;
  z-index: 1;

  a {
    color: #343434;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 0;
  }
  li {
    a {
      padding-left: 16px;
      padding-right: 32px;
      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }

  @include pc-screen() {
    height: $h_header;
  }
  @include sp-screen() {
    padding: 0;
    height: $h_header_sp;
    ul {
      display: none;
    }
  }
}

.Header__Logo {
  display: flex;
  align-items: center;
  transition: 0.4s;
  color: white;
  background: #fff;

  a {
    padding-left: 16px;
    padding-right: 32px;
  }

  @include pc-screen() {
    padding: 0 32px;
    img {
      height: 52px;
    }
  }
  @include sp-screen() {
    padding-right: 0;
    img {
      height: 40px;
    }
  }
}

.Header__Menu {
  height: 100%;
  line-height: 1;
  font-size: 28px;
  color: #343434;
  align-items: center;
  z-index: 3;
  a {
    color: #454545;
  }
  @include pc-screen() {
    display: none;
  }

  @include sp-screen() {
    display: flex;
  }
}

.Header__Menu__SearchIcon {
  margin-right: 1.4rem;
}
