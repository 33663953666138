// base width
$w_content_base: 992px;

// break point
$w_breakpoint: 600px;
$w_breakpoint_minisp: 480px;

// Height
$h_mobile_footer: 60px;
$h_header: 83px;
$h_header_sp: 70px;

// Main Color
$c_white: #fff;
$c_white-dark: #eaeaea;
$c_black: #303030;
$c_black-light: #454545;
$c_gray-dark: #c6c6c6;
$c_gray: #9f9f9f;
$c_gray-light: #f1f1f1;
$c_green: #009b8e;
