@import 'assets/styles/module/mixins';
@import 'assets/styles/module/variables';
@import 'assets/styles/normalize.scss';





































#app {
  font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
