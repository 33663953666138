*,
*::before,
*::after {
  box-sizing: border-box;
}

.v-application .success {
  background-color: $c_green !important;
  border-color: $c_green !important;
}

.v-skeleton-loader__image.v-skeleton-loader__bone {
  height: 100%;
}
